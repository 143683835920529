var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-responsive mb-4"},[_c('div',{staticClass:"small"},[_c('table',{staticClass:"table table-sm table-hover mt-4"},[_c('thead',[_c('tr',_vm._l((_vm.columnHeaders),function(h){return _c('th',{key:h.key},[_c('a',{staticClass:"sorting",attrs:{"title":"Click to sort"},on:{"click":function($event){return _vm.sortBy(h.key)}}},[_vm._v(_vm._s(h.label))])])}),0)]),_c('tbody',_vm._l((_vm.sorted),function(r){return _c('tr',{key:r.key},_vm._l((r),function(d,idx){return _c('td',{key:idx,style:({
              'background-color': _vm.getColor(d, idx),
              color: _vm.getTextColor(d, idx),
              'font-weight': _vm.getFontWeight(idx),
            })},[(_vm.numRowHeaders && idx < _vm.numRowHeaders - 1)?_c('span',[(_vm.rowHeaderFormat === 'none')?_c('span',[_vm._v(_vm._s(d))]):_c('span',[_c('router-link',{attrs:{"to":_vm.getRouterQuery(r),"title":"Open combinations viewer"}},[_vm._v(_vm._s(d))]),_vm._v("  "),(
                    idx === 0 &&
                    (_vm.rowHeaderFormat === 'lineage' ||
                      _vm.rowHeaderFormat === 'linSpikeCombo')
                  )?_c('a',{attrs:{"href":'https://outbreak.info/situation-reports?pango=' + d,"target":"_blank","title":"View on PANGO lineages"}},[_c('span',{staticClass:"material-icons text-secondary",staticStyle:{"font-size":"13px"}},[_vm._v(" open_in_new ")])]):_vm._e()],1)]):_c('span',[_vm._v(_vm._s(d))])])}),0)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }