<template>
  <div class="table-responsive mb-4">
    <div class="small">
      <table class="table table-sm table-hover mt-4">
        <thead>
          <tr>
            <th v-for="h in columnHeaders" v-bind:key="h.key">
              <!-- :class="{ rotate: needsTHRotation(i) }" -->
              <a
                class="sorting"
                v-on:click="sortBy(h.key)"
                title="Click to sort"
                >{{ h.label }}</a
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="r in sorted" v-bind:key="r.key">
            <td
              v-for="(d, idx) in r"
              v-bind:key="idx"
              :style="{
                'background-color': getColor(d, idx),
                color: getTextColor(d, idx),
                'font-weight': getFontWeight(idx),
              }"
            >
              <span v-if="numRowHeaders && idx < numRowHeaders - 1">
                <span v-if="rowHeaderFormat === 'none'">{{ d }}</span>
                <span v-else>
                  <router-link
                    :to="getRouterQuery(r)"
                    title="Open combinations viewer"
                    >{{ d }}</router-link
                  >&nbsp;&nbsp;<a
                    v-if="
                      idx === 0 &&
                      (rowHeaderFormat === 'lineage' ||
                        rowHeaderFormat === 'linSpikeCombo')
                    "
                    :href="
                      
                      'https://outbreak.info/situation-reports?pango=' + d
                    "
                    target="_blank"
                    title="View on PANGO lineages"
                    ><span
                      class="material-icons text-secondary"
                      style="font-size: 13px"
                    >
                      open_in_new
                    </span></a
                  >
                </span>
              </span>

              <!-- all other are plain text -->
              <span v-else>{{ d }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/**
 * Input:
 * tableData =
 * data: array of values for the table each row is an object {key:key, data:data}
 * columnHeaders = [{key:keyToSortDataOn,  label:labelToDisplay}]
 * dataFormatters {key:key, format: "String with $val"} $val will be replaced by value
 */
export default {
  name: "ColorCodedTable",
  props: {
    tableData: Object,
    rowHeaderFormat: String, // "lineage", "mutation", "both"
    numRowHeaders: Number,
    colors: Array, // array with colors for min, central and max values
    centralColorPoint: Number, // value to use for the central color
    formatVersion: String,
  },
  watch: {
    tableData: {
      deep: true,
      handler(newData) {
        this.processData(newData);
      },
    },
  },
  data: function () {
    return {
      data: [],
      columnHeaders: [],
      extent: [],
      sortKey: null,
      reverse: true,
      NA: "n/a",
      defaultColors: ["#b2182b", "#FFF", "#2166ac"].reverse(),
      colorScale: {},
    };
  },
  computed: {
    // downloadLink: function () {
    //   return this.$parent.proteinsTable;
    // },
    sorted: function () {
      if (this.sortKey === null) return this.data;

      let k = this.sortKey;
      let o = k === 0 ? (this.reverse ? -1 : 1) : 1;

      let compare = function (a, b) {
        let res = a[k] < b[k] ? 1 : -1;
        return res * o;
      };

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return [...this.data].sort(compare);
    },
  },
  mounted: function () {
    this.processData(this.tableData);
  },
  methods: {
    processData: function (newData) {
      if (!newData) return;
      this.data = newData.data;
      this.columnHeaders = newData.headers;
      let colors = this.colors ? colors : this.defaultColors;
      let domain = [
        newData.range[0],
        this.centralColorPoint || 1,
        newData.range[1],
      ]; //window.d3.range(newData.range[0], newData.range[1], step);
      this.colorScale = window.d3.scaleLinear().domain(domain).range(colors);

      let lastHeader =
        this.tableData.headers[this.tableData.headers.length - 1];
      lastHeader && this.sortBy(lastHeader.key);
    },
    sortBy: function (sortKey) {
      this.reverse = this.sortKey == sortKey ? !this.reverse : false;
      this.sortKey = sortKey;
      this.$emit("vttable-sorted", sortKey);
    },
    getColor(d, idx) {
      if (isNaN(d) || idx < this.numRowHeaders) return "#FFF";
      let c = this.colorScale(d) || "#FFF";
      return c;
    },
    getTextColor(d, idx) {
      if (isNaN(d) || idx < this.numRowHeaders) return "#000";
      let c = this.colorScale(d);

      let rgb = window.d3.rgb(c);
      //https://gomakethings.com/dynamically-changing-the-text-color-based-on-background-color-contrast-with-vanilla-js/
      // Get YIQ ratio
      let yiq = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;

      // Check contrast
      return yiq >= 128 ? "#000" : "#FFF";
    },
    getFontWeight(i) {
      let w = i < this.numRowHeaders ? "bold" : "normal";
      return w;
    },
    needsTHRotation(columnIndex) {
      return columnIndex > 0 && columnIndex < this.columnHeaders.length - 1;
    },
    getRouterQuery(row) {
      //
      let l = "";
      let m = "";
      let e = "true";
      let p = "SPIKE";
      let d = row[0].trim();
      let f = this.formatVersion;

      if (this.rowHeaderFormat === "linSpikeCombo") {
          l = row[0] ? row[0].trim() : "";
          m = row[1] ? row[1].trim().replaceAll(" ", "_") : "";
        
      } else if (this.rowHeaderFormat === "lineage") {
        l = d;
        e = "false";
      } else if (this.rowHeaderFormat === "mutation") {
        let pts = d.trim().split("_");
        if (pts.length > 1) {
          p = pts[0];
          m = pts[1];
        } else {
          m = d.replaceAll(" ", "_");
        }
        e = "false";
      } else if (this.rowHeaderFormat === "spikeMut") {
        m = d.replaceAll(" ", "_");

        e = "false";
      } else if (this.rowHeaderFormat === "spikeMutCombo") {
        m = d.replaceAll(" ", "_");
      }

      let q = { path: "/cv", query: { l: l, p: p, m: m, e: e, f: f } };
      return q;
    },
  },
};
</script>

<style scoped>
a.sorting {
  font-weight: bolder;
  color: #007bff;
  cursor: pointer;
}
table thead th {
  vertical-align: middle;
}
th.rotate {
  padding-bottom: 5px;
  transform: rotate(-45deg);

  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  -webkit-transform: rotate(-45deg);

  /* Firefox */
  -moz-transform: rotate(-45deg);

  /* IE */
  -ms-transform: rotate(-45deg);

  /* Opera */
  -o-transform: rotate(-45deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
</style>
